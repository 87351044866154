[data-display_type^="accordion"],
[data-display_type="always_open"] {
  .x13faq__question-wrapper {
    display: block;
    margin-bottom: 16px;
    &.x13none {
      display: none;
    }
    &.card,
    &.panel {
      border-radius: 0;
      box-shadow: none;
      border: none;
    }
  }
  .x13faq__question {
    background: var(--x13faq-headings_bg_color);
    padding: 12px 20px !important;
    font-size: 16px !important;
    color: var(--x13faq-headings_text_color);
    line-height: 20px;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.15s ease;
  }
  .x13faq__question-content {
    padding: 0;
    margin: 0;
    border: 1px solid var(--x13faq-headings_bg_color);
  }
  .x13faq__answer,
  .x13faq__question-rate {
    margin: 20px;
    padding: 0;
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

[data-display_type="always_open"] {
  .x13faq__question {
    background: var(--x13faq-headings_bg_color_active);
    color: var(--x13faq-headings_text_color_active);
    cursor: unset;
    svg {
      display: none;
    }
  }
  .x13faq__question-content {
    border-color: var(--x13faq-headings_bg_color_active);
  }
}

[data-display_type^="accordion"] {
  .x13faq__question-wrapper {
    .x13faq__question {
      &.x13faq__svg-right {
        flex-direction: row-reverse;
        justify-content: space-between;
         svg {
          margin-right: 0px;
          margin-left: 12px;
         }
      }
      svg {
        margin-right: 12px;
        width: 20px;
        flex: 0 0 20px;
        transform: rotateZ(45deg);
        fill: var(--x13faq-headings_text_color);
        transition: .25s transform ease;
      }
    }
    .x13faq__question-content {
      display: none;
    }
    &.open {
      .x13faq__question {
        background: var(--x13faq-headings_bg_color_active);
        color: var(--x13faq-headings_text_color_active);
        svg {
          transform: rotateZ(0deg);
          fill: var(--x13faq-headings_text_color_active);
        }
      }
      .x13faq__question-content {
        border: 1px solid var(--x13faq-headings_bg_color_active);
      }
  }
  }
}
[data-display_type^="list"] {
  ul {
    width: 100%;
    li {
      .x13faq__question {
        margin: 0;
        padding: 0;
        border-bottom: 1px solid #f6f6f6;
        a {
          display: block;
          margin: 0;
          padding: 6px 8px;
          color: #333;
          font-weight: 400;
          font-size: 16px;
          &:focus {
            outline: 0;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
      display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
      display: none!important;
  }
}

#x13faq__category-faq > div {
  display: none;
}

.mt-2 {
    margin-top: 1.5rem!important;
}