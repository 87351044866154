.x13faq {
  &__latest-block {
    margin-bottom: 16px;
    &:not(:last-child) {
      border-bottom: 1px solid #f6f6f6;
      padding-bottom: 8px
    }
  }
  &__latest-title {
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin-top: 8px;
    margin-bottom: 4px;
  }
  &__latest-content {
      font-size: 14px;
      text-align: left;
      margin-bottom: 8px;
  }
  &__latest-more {
    display: block;
    font-size: 14px;
    text-align: right;
  }
}