.x13faq__search {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #f6f6f6;
  &-wrapper {
    position: relative;
    overflow: hidden;
  }
  &-clear {
    display: block;
    width: 24px;
    height: 24px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='black' width='24px' height='24px'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%)  translateX(150%);
    transition: .2s transform linear;
    opacity: .4;
    &:hover {
      opacity: .7;
      cursor: pointer;
    }
    &.x13show {
      transform: translateY(-50%) translateX(0);
    }
  }
  &-input {
    padding: 11px 38px 11px 11px;
    border: 1px solid #bdbcbd;
    border-right: 0;
    border-radius: 0;
    width: 660px;
    max-width: 100%;
    font-size: 16px;
    line-height: 20px;
    height: 44px;
    &:focus {
      border-radius: 0;
    }
  }
  &-btn {
    padding: 11px 36px;
    box-shadow: none;
    height: 44px;
    font-size: 16px;
    line-height: 20px;
    svg {
      margin-top: -5px;
      margin-bottom: -6px;
    }
  }
}