.x13faq {
  &__pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 767px) {
      flex-direction: column-reverse;
      text-align:center;
      margin-bottom: 20px;
    }
    .page-list {
      background: transparent;
      box-shadow: none;
      padding: 0;
    }
  }
}

.x13faq__pagination .top-pagination-content div.pagination {
  width: 100%;
  text-align: right;
}