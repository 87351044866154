.x13faq {
  $self: &;
  &__question-rate {
    text-align: right;
  }
  &__block-description {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &__category-title {
    margin-bottom: 12px;
    .x13faq__menu-link {
      display: flex;
      align-items: stretch;
      h2 {
        line-height: 24px;
        font-size: 20px;
        display: inline;
        margin: 0;
      }
      i {
        color: #333;
        font-size: 24px;
        margin: 0 8px 0 0;
      }
      span {
        line-height: 24px;
        color: #787878;
        font-size: 14px;
        margin: 0 0 0 4px;
      }
    }
  }
  &__other-heading {
    margin: 20px 0 16px 0;
  }
  &__show-all {
    text-align: right;
  }
  &__mark {
    background: #ff0;
    padding: 0;
    color: #000;
  }
  &__question-and-answers {
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}