.x13faq {
  $self: &;
  &__category-row,
  &__section.row {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    border: none;
    box-shadow: none;
    @media (max-width: 767px) {
      > .col-sm-12 {
        width: 100%;
        flex: 0 0 100%;
      }
    }
  }
}