/*
--x13faq-headings_bg_color
--x13faq-headings_text_color
--x13faq-headings_bg_color_active
--x13faq-headings_text_color_active
*/
@import "global";
@import "right-menu";
@import "default";
@import "search";
@import "rate";
@import "multicolumns";
@import "pagination";
@import "latest";
@import "block";

//@import "styles/border-left";
//@import "styles/plus-background";
