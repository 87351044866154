.x13faq {
  &__menu-right,
  &__left-column {
    &.x13faq_with-questions {
      @media (min-width: 768px) {
        .x13faq__menu-title {
          display: none;
        }
      }
    }
    &.card-block {
      margin-bottom: 1.563rem;
    }
    .x13faq__menu-title {
      margin-bottom: 20px;
    }
      ul  {
        margin: 0 0 20px 0;
        li {
          border-bottom: 1px solid #f6f6f6;
          &:last-child {
            border-bottom: none;
          }
          a {
            display: block;
            padding: 8px;
            color: #7a7a7a;
            font-size: 16px;
            font-weight: bold;
            &:hover {
              text-decoration: underline;
            }
            &:focus {
              outline: 0;
            }
          }
          &.active {
            a {
              color: #2fb5d2;
          } 
            ul {
              margin: 0;
              li {
                border-bottom: none;
                a {
                  padding: 4px 8px;
                  color: #7a7a7a;
                  font-size: 14px;
                  font-weight: 400;
                }
                &:last-child {
                  margin-bottom: 8px;
                }
              }
            }
          }
        }
      }
    } 
    &__menu-title {
      display: block;
    }
  }
