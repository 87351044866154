.x13faq__thumb {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  color: #7b7b7b;
  svg {
    fill: #7b7b7b;
    display: inline-block;
    width: 18px;
    height: 18px;
  }
  &--down {
    margin-left: 16px; 
    svg {
      transform: rotateZ(180deg);
    }
  }
  .border {
    display: inline-block;
  }
  .fill {
    display: none;
    fill: #232323;
  }
  &.active {
    color: #232323;
    .border {
      display: none;
    }
    .fill {
      display: inline-block;
    }
  }
}

.x13faq {
  &__likes,
  &__unlikes {
    vertical-align: top;
  }
}