/*
--x13faq-headings_bg_color
--x13faq-headings_text_color
--x13faq-headings_bg_color_active
--x13faq-headings_text_color_active
*/
.x13faq__question-rate {
  text-align: right; }

.x13faq__block-description {
  margin-top: 20px;
  margin-bottom: 20px; }

.x13faq__category-title {
  margin-bottom: 12px; }
  .x13faq__category-title .x13faq__menu-link {
    display: flex;
    align-items: stretch; }
    .x13faq__category-title .x13faq__menu-link h2 {
      line-height: 24px;
      font-size: 20px;
      display: inline;
      margin: 0; }
    .x13faq__category-title .x13faq__menu-link i {
      color: #333;
      font-size: 24px;
      margin: 0 8px 0 0; }
    .x13faq__category-title .x13faq__menu-link span {
      line-height: 24px;
      color: #787878;
      font-size: 14px;
      margin: 0 0 0 4px; }

.x13faq__other-heading {
  margin: 20px 0 16px 0; }

.x13faq__show-all {
  text-align: right; }

.x13faq__mark {
  background: #ff0;
  padding: 0;
  color: #000; }

@media (max-width: 767px) {
  .x13faq__question-and-answers {
    display: flex;
    flex-direction: column-reverse; } }

@media (min-width: 768px) {
  .x13faq__menu-right.x13faq_with-questions .x13faq__menu-title, .x13faq__left-column.x13faq_with-questions .x13faq__menu-title {
    display: none; } }

.x13faq__menu-right.card-block, .x13faq__left-column.card-block {
  margin-bottom: 1.563rem; }

.x13faq__menu-right .x13faq__menu-title, .x13faq__left-column .x13faq__menu-title {
  margin-bottom: 20px; }

.x13faq__menu-right ul, .x13faq__left-column ul {
  margin: 0 0 20px 0; }
  .x13faq__menu-right ul li, .x13faq__left-column ul li {
    border-bottom: 1px solid #f6f6f6; }
    .x13faq__menu-right ul li:last-child, .x13faq__left-column ul li:last-child {
      border-bottom: none; }
    .x13faq__menu-right ul li a, .x13faq__left-column ul li a {
      display: block;
      padding: 8px;
      color: #7a7a7a;
      font-size: 16px;
      font-weight: bold; }
      .x13faq__menu-right ul li a:hover, .x13faq__left-column ul li a:hover {
        text-decoration: underline; }
      .x13faq__menu-right ul li a:focus, .x13faq__left-column ul li a:focus {
        outline: 0; }
    .x13faq__menu-right ul li.active a, .x13faq__left-column ul li.active a {
      color: #2fb5d2; }
    .x13faq__menu-right ul li.active ul, .x13faq__left-column ul li.active ul {
      margin: 0; }
      .x13faq__menu-right ul li.active ul li, .x13faq__left-column ul li.active ul li {
        border-bottom: none; }
        .x13faq__menu-right ul li.active ul li a, .x13faq__left-column ul li.active ul li a {
          padding: 4px 8px;
          color: #7a7a7a;
          font-size: 14px;
          font-weight: 400; }
        .x13faq__menu-right ul li.active ul li:last-child, .x13faq__left-column ul li.active ul li:last-child {
          margin-bottom: 8px; }

.x13faq__menu-title {
  display: block; }

[data-display_type^="accordion"] .x13faq__question-wrapper,
[data-display_type="always_open"] .x13faq__question-wrapper {
  display: block;
  margin-bottom: 16px; }
  [data-display_type^="accordion"] .x13faq__question-wrapper.x13none,
  [data-display_type="always_open"] .x13faq__question-wrapper.x13none {
    display: none; }
  [data-display_type^="accordion"] .x13faq__question-wrapper.card, [data-display_type^="accordion"] .x13faq__question-wrapper.panel,
  [data-display_type="always_open"] .x13faq__question-wrapper.card,
  [data-display_type="always_open"] .x13faq__question-wrapper.panel {
    border-radius: 0;
    box-shadow: none;
    border: none; }

[data-display_type^="accordion"] .x13faq__question,
[data-display_type="always_open"] .x13faq__question {
  background: var(--x13faq-headings_bg_color);
  padding: 12px 20px !important;
  font-size: 16px !important;
  color: var(--x13faq-headings_text_color);
  line-height: 20px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.15s ease; }

[data-display_type^="accordion"] .x13faq__question-content,
[data-display_type="always_open"] .x13faq__question-content {
  padding: 0;
  margin: 0;
  border: 1px solid var(--x13faq-headings_bg_color); }

[data-display_type^="accordion"] .x13faq__answer,
[data-display_type^="accordion"] .x13faq__question-rate,
[data-display_type="always_open"] .x13faq__answer,
[data-display_type="always_open"] .x13faq__question-rate {
  margin: 20px;
  padding: 0; }
  [data-display_type^="accordion"] .x13faq__answer img,
  [data-display_type^="accordion"] .x13faq__question-rate img,
  [data-display_type="always_open"] .x13faq__answer img,
  [data-display_type="always_open"] .x13faq__question-rate img {
    max-width: 100%;
    height: auto; }

[data-display_type="always_open"] .x13faq__question {
  background: var(--x13faq-headings_bg_color_active);
  color: var(--x13faq-headings_text_color_active);
  cursor: unset; }
  [data-display_type="always_open"] .x13faq__question svg {
    display: none; }

[data-display_type="always_open"] .x13faq__question-content {
  border-color: var(--x13faq-headings_bg_color_active); }

[data-display_type^="accordion"] .x13faq__question-wrapper .x13faq__question.x13faq__svg-right {
  flex-direction: row-reverse;
  justify-content: space-between; }
  [data-display_type^="accordion"] .x13faq__question-wrapper .x13faq__question.x13faq__svg-right svg {
    margin-right: 0px;
    margin-left: 12px; }

[data-display_type^="accordion"] .x13faq__question-wrapper .x13faq__question svg {
  margin-right: 12px;
  width: 20px;
  flex: 0 0 20px;
  transform: rotateZ(45deg);
  fill: var(--x13faq-headings_text_color);
  transition: .25s transform ease; }

[data-display_type^="accordion"] .x13faq__question-wrapper .x13faq__question-content {
  display: none; }

[data-display_type^="accordion"] .x13faq__question-wrapper.open .x13faq__question {
  background: var(--x13faq-headings_bg_color_active);
  color: var(--x13faq-headings_text_color_active); }
  [data-display_type^="accordion"] .x13faq__question-wrapper.open .x13faq__question svg {
    transform: rotateZ(0deg);
    fill: var(--x13faq-headings_text_color_active); }

[data-display_type^="accordion"] .x13faq__question-wrapper.open .x13faq__question-content {
  border: 1px solid var(--x13faq-headings_bg_color_active); }

[data-display_type^="list"] ul {
  width: 100%; }
  [data-display_type^="list"] ul li .x13faq__question {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #f6f6f6; }
    [data-display_type^="list"] ul li .x13faq__question a {
      display: block;
      margin: 0;
      padding: 6px 8px;
      color: #333;
      font-weight: 400;
      font-size: 16px; }
      [data-display_type^="list"] ul li .x13faq__question a:focus {
        outline: 0; }

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important; } }

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important; } }

#x13faq__category-faq > div {
  display: none; }

.mt-2 {
  margin-top: 1.5rem !important; }

.x13faq__search {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #f6f6f6; }
  .x13faq__search-wrapper {
    position: relative;
    overflow: hidden; }
  .x13faq__search-clear {
    display: block;
    width: 24px;
    height: 24px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='black' width='24px' height='24px'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%) translateX(150%);
    transition: .2s transform linear;
    opacity: .4; }
    .x13faq__search-clear:hover {
      opacity: .7;
      cursor: pointer; }
    .x13faq__search-clear.x13show {
      transform: translateY(-50%) translateX(0); }
  .x13faq__search-input {
    padding: 11px 38px 11px 11px;
    border: 1px solid #bdbcbd;
    border-right: 0;
    border-radius: 0;
    width: 660px;
    max-width: 100%;
    font-size: 16px;
    line-height: 20px;
    height: 44px; }
    .x13faq__search-input:focus {
      border-radius: 0; }
  .x13faq__search-btn {
    padding: 11px 36px;
    box-shadow: none;
    height: 44px;
    font-size: 16px;
    line-height: 20px; }
    .x13faq__search-btn svg {
      margin-top: -5px;
      margin-bottom: -6px; }

.x13faq__thumb {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  color: #7b7b7b; }
  .x13faq__thumb svg {
    fill: #7b7b7b;
    display: inline-block;
    width: 18px;
    height: 18px; }
  .x13faq__thumb--down {
    margin-left: 16px; }
    .x13faq__thumb--down svg {
      transform: rotateZ(180deg); }
  .x13faq__thumb .border {
    display: inline-block; }
  .x13faq__thumb .fill {
    display: none;
    fill: #232323; }
  .x13faq__thumb.active {
    color: #232323; }
    .x13faq__thumb.active .border {
      display: none; }
    .x13faq__thumb.active .fill {
      display: inline-block; }

.x13faq__likes, .x13faq__unlikes {
  vertical-align: top; }

.x13faq__category-row, .x13faq__section.row {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  border: none;
  box-shadow: none; }
  @media (max-width: 767px) {
    .x13faq__category-row > .col-sm-12, .x13faq__section.row > .col-sm-12 {
      width: 100%;
      flex: 0 0 100%; } }

.x13faq__pagination {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media (max-width: 767px) {
    .x13faq__pagination {
      flex-direction: column-reverse;
      text-align: center;
      margin-bottom: 20px; } }
  .x13faq__pagination .page-list {
    background: transparent;
    box-shadow: none;
    padding: 0; }

.x13faq__pagination .top-pagination-content div.pagination {
  width: 100%;
  text-align: right; }

.x13faq__latest-block {
  margin-bottom: 16px; }
  .x13faq__latest-block:not(:last-child) {
    border-bottom: 1px solid #f6f6f6;
    padding-bottom: 8px; }

.x13faq__latest-title {
  display: block;
  font-size: 14px;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 4px; }

.x13faq__latest-content {
  font-size: 14px;
  text-align: left;
  margin-bottom: 8px; }

.x13faq__latest-more {
  display: block;
  font-size: 14px;
  text-align: right; }

.x13faq__block-faq_product.card.card-block {
  margin-top: 24px; }
  .x13faq__block-faq_product.card.card-block .x13faq__block-title {
    margin-bottom: 16px; }

.x13faq__block-faq_category.card.card-block .x13faq__block-title {
  margin-bottom: 16px; }

.x13faq__block-questions {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch; }

@media (max-width: 767px) {
  .x13faq__block-latest, .x13faq__left-column, .x13faq__menu-right {
    margin-bottom: 0px !important;
    padding: 0; } }

@media (max-width: 767px) {
  .x13faq__block-latest .title, .x13faq__left-column .title, .x13faq__menu-right .title {
    margin: 0;
    padding: .625rem 0;
    border-bottom: 1px solid #f6f6f6;
    cursor: pointer; }
    .x13faq__block-latest .title .h3, .x13faq__left-column .title .h3, .x13faq__menu-right .title .h3 {
      font-size: 1rem; } }

.x13faq__block-latest .title.collapsed .collapse-icons .add, .x13faq__left-column .title.collapsed .collapse-icons .add, .x13faq__menu-right .title.collapsed .collapse-icons .add {
  display: inline-block; }

.x13faq__block-latest .title.collapsed .collapse-icons .remove, .x13faq__left-column .title.collapsed .collapse-icons .remove, .x13faq__menu-right .title.collapsed .collapse-icons .remove {
  display: none; }

.x13faq__block-latest .title .collapse-icons .add, .x13faq__left-column .title .collapse-icons .add, .x13faq__menu-right .title .collapse-icons .add {
  display: none; }

.x13faq__block-latest .title .collapse-icons .remove, .x13faq__left-column .title .collapse-icons .remove, .x13faq__menu-right .title .collapse-icons .remove {
  display: inline-block; }

.x13faq__block-latest .title:hover, .x13faq__left-column .title:hover, .x13faq__menu-right .title:hover {
  cursor: pointer; }

@media (min-width: 768px) {
  .x13faq__block-latest .collapse, .x13faq__left-column .collapse, .x13faq__menu-right .collapse {
    display: block; } }

@media (max-width: 767px) {
  .x13faq__menu-right {
    margin-bottom: 20px !important; } }
